import React from 'react';
import Layout from '../components/layout';

const PageNotFound = (props) => {
  return (
    <Layout location={props.location}>
      <h1>Oops, page is not found</h1>
      <p>Are you sure this page exists?</p>
    </Layout>
  );
};

export default PageNotFound;
